import { ChangeParametersTemplate } from "../templates/ChangeParametersTemplate";

export const ChangeParametersPage = () => {


    return (
       <>
            <ChangeParametersTemplate />
       </>
    );
};
